// import { Goerli } from "@usedapp/core";
// import { SUPPORTED_CHAIN } from "../../react-app/src";

const addresses  = {
depositToken: "0x7a5d62df1E5754757495bB8B8747d3272C41d6d6",
bitsNftBank: "0x2d26132F80Ff5eE5a5DA03046C6F15cb3f4C971F",
bitsNftMint: "0x403F430167864D5e30CE7DdC445Ec6c1C6B621Ff",
bitsNftUri: "0x1b51A50D3105546428cbbC7DfB7b75Ea793E2909",
wbtcFaucet: "0xa5B64FF6ad41533D616083633f9561ef65b5d242",
};
export default addresses;

// Mainnet
// depositToken: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
// bitsNftBank: "0xd174c6AEa8483D1233c8faab9bDA19dcb0c8B7FC",
// bitsNftMint: "0x1f8a20f57564b0380D15701f1508bE73A3Be6569",
// bitsNftUri: "0x5f5239273e88c941D5053A1847d1F4A4f15c34F8",
// wbtcFaucet: "0xa5B64FF6ad41533D616083633f9561ef65b5d242",
// Rinkeby
// depositToken: "0x7a5d62df1E5754757495bB8B8747d3272C41d6d6",
// bitsNftBank: "0x2d26132F80Ff5eE5a5DA03046C6F15cb3f4C971F",
// bitsNftMint: "0x403F430167864D5e30CE7DdC445Ec6c1C6B621Ff",
// bitsNftUri: "0x1b51A50D3105546428cbbC7DfB7b75Ea793E2909",
// wbtcFaucet: "0xa5B64FF6ad41533D616083633f9561ef65b5d242",